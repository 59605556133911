import { CodeBaseOption } from "../../../../components/Fields/types";

export function filterDealTypes(options: CodeBaseOption[]) {
  const ignoredTypes = [
    "exportLC",
    "outgoingStandbyLC",
    "outgoingStandbyBG",
    "incomingStandbyLC",
    "incomingStandbyBG",
    "receivedGuarantee",
    "importCollection",
    "incomingStandby",
  ];
  return options.filter((option) => !ignoredTypes.includes(option.value));
}
