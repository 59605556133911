import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ConfirmButton } from "../../../../../components";
import { Delete, Edit } from "../../../../../icons/Icons";
import { BeneficiaryCardProps } from "../typest";

function BeneficiaryCard(props: BeneficiaryCardProps) {
  const { id, bankName, name, bankSwiftAddress } = props.beneficiary;
  const { t } = useTranslation();

  return (
    <div className="list-card beneficiary-card">
      <div className="details">
        <div className="data-columns-container">
          <div className={"data-columns columns guarantee-columns"}>
            <div className={"column"}>
              <div className="card-title">{name}</div>
            </div>
            <div className={"column"}>
              <div>{bankName}</div>
            </div>
            <div className={"column"}>
              <div>{bankSwiftAddress}</div>
            </div>
            <div className={"column is-narrow"}>
              <div className="card-buttons-container">
                <div className="buttons horizontal-buttons">
                  <Link
                    className="card-button"
                    to={`/portal/beneficiary/hu.appello.kbtradefin:kbtf-portal:jar:3.1.13`}
                  >
                    <Edit />
                    <div className="button-label">{t("edit")}</div>
                  </Link>

                  <div className={classnames("card-button has-red-hover")}>
                    <ConfirmButton
                      confirmation={t("areYouSureToDelete")}
                      button={
                        <div onClick={() => props.deleteBeneficiary(id)}>
                          <Delete />
                        </div>
                      }
                    />
                    <div className="button-label">{t("delete")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BeneficiaryCard;
