import * as H from "history";
import {
  ChipsFilter,
  TradeFinance,
  TradeFinanceSearchParams,
} from "../../../types";
import * as TradeFinanceApi from "../../../api/tradeFinanceApi";
import moment from "moment";

export function tradeFinanceLink(
  tradeFinance: TradeFinance,
  isSignatureRequired: boolean
): string {
  if (
    isSignatureRequired &&
    tradeFinance.signature &&
    tradeFinance.signature.eventId
  ) {
    return `/portal/trade-finance-request/${tradeFinance.id}?event=${tradeFinance.signature.eventId}`;
  }
  if (tradeFinance.event) {
    return `/portal/trade-finance-request/${tradeFinance.id}?event=${tradeFinance.event.id}`;
  }
  if (tradeFinance.state === "concept") {
    return `/portal/trade-finance-request/${tradeFinance.id}`;
  }
  return `/portal/trade-finance-request/${tradeFinance.id}`;
}

export async function getAmendmentAction(
  id: string,
  openModal: () => void,
  history: H.History
) {
  const hasInProgressAmendmentEvent =
    await TradeFinanceApi.hasInProgressAmendmentEvent(id);
  const hasInProgressCancelEvent =
    await TradeFinanceApi.hasInProgressCancelEvent(id);

  if (hasInProgressAmendmentEvent || hasInProgressCancelEvent) {
    openModal();
  } else {
    const eventId = await TradeFinanceApi.amendment(id);
    if (eventId) {
      history.push(`/portal/trade-finance-request/hu.appello.kbtradefin:kbtf-portal:jar:3.1.13?event=${eventId.id}`);
    }
  }
}

export function urlSearchParamsToTradeFinanceSearchParams(
  urlSearchParams: URLSearchParams
): TradeFinanceSearchParams {
  if (Array.from(urlSearchParams.keys()).length > 0) {
    const params = {
      search: urlSearchParams.get("search") || undefined,
      referenceNo: urlSearchParams.get("referenceNo") || undefined,
      minAmount: urlSearchParams.get("minAmount")
        ? parseFloat(urlSearchParams.get("minAmount")!)
        : undefined,
      maxAmount: urlSearchParams.get("maxAmount")
        ? parseFloat(urlSearchParams.get("maxAmount")!)
        : undefined,
      otherPartnerName: urlSearchParams.get("otherPartnerName") || undefined,
      identifier: urlSearchParams.get("identifier") || undefined,
      minDateOfExpiry: urlSearchParams.get("minDateOfExpiry")
        ? moment(parseInt(urlSearchParams.get("minDateOfExpiry")!)).toDate()
        : undefined,
      maxDateOfExpiry: urlSearchParams.get("maxDateOfExpiry")
        ? moment(parseInt(urlSearchParams.get("maxDateOfExpiry")!)).toDate()
        : undefined,
      currency: urlSearchParams.get("currency")?.split(",") ?? undefined,
      businessEntityDescriptorId:
        urlSearchParams.get("businessEntityDescriptorId")?.split(",") ??
        undefined,
      eventDescriptorId:
        urlSearchParams.get("eventDescriptorId")?.split(",") ?? undefined,
      subType: urlSearchParams.get("subType")?.split(",") ?? undefined,
      events: urlSearchParams.get("events")?.split(",") ?? undefined,
      chipsFilter: urlSearchParams.get("chipsFilter") || ChipsFilter.activated,
      sortOption: urlSearchParams.get("sortOption") ?? undefined,
    };
    Object.entries(params)
      .filter(([k, v]) => v === undefined)
      .forEach(([k, v]) => delete (params as any)[k]);
    return params;
  } else {
    return {};
  }
}

export function tfSearchParamsToUrlSearchParams(
  searchParams: TradeFinanceSearchParams
): URLSearchParams {
  const urlSearchParams = new URLSearchParams();

  if (searchParams.type) {
    urlSearchParams.append("type", searchParams.type);
  }
  if (searchParams.search) {
    urlSearchParams.append("search", searchParams.search);
  }
  if (searchParams.referenceNo) {
    urlSearchParams.append("referenceNo", searchParams.referenceNo);
  }
  if (searchParams.minAmount) {
    urlSearchParams.append("minAmount", searchParams.minAmount + "");
  }
  if (searchParams.maxAmount) {
    urlSearchParams.append("maxAmount", searchParams.maxAmount + "");
  }
  if (searchParams.otherPartnerName) {
    urlSearchParams.append("otherPartnerName", searchParams.otherPartnerName);
  }
  if (searchParams.identifier) {
    urlSearchParams.append("identifier", searchParams.identifier);
  }
  if (searchParams.minDateOfExpiry) {
    urlSearchParams.append(
      "minDateOfExpiry",
      searchParams.minDateOfExpiry.getTime() + ""
    );
  }
  if (searchParams.maxDateOfExpiry) {
    urlSearchParams.append(
      "maxDateOfExpiry",
      searchParams.maxDateOfExpiry.getTime() + ""
    );
  }
  if (searchParams.currency) {
    urlSearchParams.append("currency", searchParams.currency.join(","));
  }
  if (searchParams.businessEntityDescriptorId) {
    urlSearchParams.append(
      "businessEntityDescriptorId",
      searchParams.businessEntityDescriptorId.join(",")
    );
  }
  if (searchParams.eventDescriptorId) {
    urlSearchParams.append(
      "eventDescriptorId",
      searchParams.eventDescriptorId.join(",")
    );
  }
  if (searchParams.subType) {
    urlSearchParams.append("subType", searchParams.subType.join(","));
  }
  if (searchParams.chipsFilter) {
    urlSearchParams.append("chipsFilter", searchParams.chipsFilter);
  }
  if (searchParams.sortOption) {
    urlSearchParams.append("sortOption", searchParams.sortOption);
  }
  if (searchParams.events && searchParams.events.length) {
    urlSearchParams.append("events", searchParams.events.join(","));
  }

  return urlSearchParams;
}
