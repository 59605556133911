import moment from "moment";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../../App";
import {
  DateFieldProps,
  FieldProps,
  FieldType,
  NumberFieldProps,
  SelectFieldProps,
  SwiftCodeFieldProps,
  SwiftFieldProps,
  TextAreaWithClausesProps,
  TextWithClausesProps,
} from "../../../components/Fields/types";
import { collectFields, getFieldProps } from "../../../components/Form/helper";
import {
  CollapsibleState,
  ContainerDesc,
  ContainerType,
  FieldMap,
} from "../../../components/Form/types";
import { useLoadingAction, usePrevious } from "../../../hooks";
import { TradeFinanceDetail } from "../../../api/tradeFinanceApi";
import {
  Collection,
  Guarantee,
  LetterOfCredit,
  Partner,
  TradeFinance,
  TradeFinancePartner,
  Univ,
} from "../../../types";
import { TradFinanceRequestContext } from "./context";
import {
  filterSelectOptions,
  findContainerByField,
  getEventFieldProps,
  insertFieldsAfter,
  nullSafeEqual,
  partnerFieldMapping,
  partnerFields,
  setEditable,
  setRequiredAndVisible,
  setVisible,
  today,
} from "./helper";
import {
  businessEntityDescriptorRefName,
  getCurrencyDecimals,
  isAmendment,
  isCancelEvent,
  isCollection,
  isEventWaitingForClient,
  isGuarantee,
  isImportLetterOfCredit,
  isImportLetterOfCreditInCancelEventWaitingForClient,
  isLetterOfCredit,
  isOutgoingStandBy,
  isOutgoingTradeFinance,
  isPreCheckEvent,
  isTradeFinanceEditable,
} from "./tradeFinanceUtils";
import { StepState, TradeFinanceDetailsChanges } from "./type";
import * as TFApi from "../../../api/tradeFinanceApi";
import { FormikProps } from "formik";
import { TFunction } from "i18next";

export function useIsTemplate(
  tradeFinance: TradeFinance | null | undefined
): boolean {
  const location = useLocation();
  return (
    !!(tradeFinance && tradeFinance.state === "template") ||
    location.pathname.includes("/template/")
  );
}

export function useStepState(
  tradeFinance: TradeFinance,
  layout: ContainerDesc
): StepState {
  const { formFields } = useContext(TradFinanceRequestContext);
  const fieldsOnLayout = useMemo(() => collectFields(layout), [layout]);

  const state: StepState = useMemo(
    () =>
      fieldsOnLayout
        .filter((field) => {
          const fieldProps = (formFields as any)[field.id];
          return fieldProps?.required && !fieldProps?.hidden;
        })
        .every((field) => {
          let fieldValue = (tradeFinance as any)[field.id];
          return (
            fieldValue !== "" && fieldValue !== null && fieldValue !== undefined
          );
        })
        ? "finished"
        : "inProgress",
    [tradeFinance, formFields]
  );
  return state;
}

export function useTradeFinanceDetailsChanges(): TradeFinanceDetailsChanges {
  const {
    clientDetailsLayout,
    requestDetailsLayout,
    form,
    origTradeFinance,
    formFields,
  } = useContext(TradFinanceRequestContext);

  function areFieldsChanged(fields: FieldProps[]) {
    const changed = !fields.every((field) => {
      const fieldId = field.id;
      if ((formFields as any)[fieldId]?.hidden) {
        return true;
      }
      const origValue = (origTradeFinance as any)[fieldId];
      const value = (form.values as any)[fieldId];
      const equals = nullSafeEqual(origValue, value);

      return equals;
    });

    return changed;
  }

  const isInitialized =
    origTradeFinance &&
    Object.keys(origTradeFinance).length > 0 &&
    Object.keys(form.values).length > 0;

  const clientDetailsFields = useMemo(
    () => collectFields(clientDetailsLayout),
    [clientDetailsLayout]
  );

  const requestDetailsFields = useMemo(
    () => collectFields(requestDetailsLayout),
    [requestDetailsLayout]
  );

  const requestDetailsChanged = useMemo(() => {
    return isInitialized && areFieldsChanged(requestDetailsFields);
  }, [requestDetailsFields, form.values, origTradeFinance]);

  const partnerDetailsChanged = useMemo(
    () => isInitialized && areFieldsChanged(clientDetailsFields),
    [clientDetailsFields, form.values, origTradeFinance]
  );

  const referenceNoChanged = useMemo(
    () =>
      isInitialized &&
      !nullSafeEqual(origTradeFinance.referenceNo, form.values.referenceNo),
    [form.values, origTradeFinance]
  );

  const documentRequirementChanged = useMemo(
    () =>
      isInitialized &&
      !nullSafeEqual(
        origTradeFinance.requiredDocuments,
        form.values.requiredDocuments
      ),
    [form.values, origTradeFinance]
  );

  const eventChanged = useMemo(
    () =>
      isInitialized &&
      !nullSafeEqual(origTradeFinance.event, form.values.event),
    [form.values, origTradeFinance]
  );

  return {
    requestDetailsChanged,
    partnerDetailsChanged,
    referenceNoChanged,
    documentRequirementChanged,
    eventChanged,
  };
}

function clearPartnerFields(form: FormikProps<TradeFinanceDetail>) {
  const partnerFieldValues = {} as any;
  for (let [tfField, partnerField] of Object.entries(partnerFieldMapping)) {
    partnerFieldValues[tfField] = undefined;
    form.setFieldValue(tfField, undefined);
  }
}

//TODO
function getAmendmentDetailsField(t: TFunction): FieldProps {
  return {
    id: "event.amendmentDetails",
    type: FieldType.textArea,
    label: t("tradeFinance.amendmentDetails"),
    help: {
      en: t("tradeFinance.amendmentDetailsHelp", { lng: "en" }),
      cz: t("tradeFinance.amendmentDetailsHelp", { lng: "cz" }),
    } as Univ,
  } as FieldProps;
}

export function useFieldAndLayoutModifications(): [
  ContainerDesc,
  ContainerDesc
] {
  const { lang, user, userStatus } = useContext(AppContext);
  const { t } = useTranslation();
  const tfinContext = useContext(TradFinanceRequestContext);
  const [isFirstLoading, setFirstLoading] = useState(true);
  const {
    tradeFinance,
    //origTradeFinance,
    form,
    clientDetailsLayout,
    requestDetailsLayout,
    formFields,
    loadPartnersAction,
    setFormFields,
    setNewPartnerOption,
    createNewPartnerOption,
  } = tfinContext;

  const isEditEnabled =
    isTradeFinanceEditable(tfinContext) && !isPreCheckEvent(tradeFinance);
  const template = useIsTemplate(tradeFinance);
  const prevFavouriteBeneficiary = useRef<number>();
  const prevGuaranteeMainType = usePrevious<string>(
    (form.values as any as Guarantee).guaranteeMainType
  );
  const [isFirstRender, setFirstRender] = useState(true);

  const partnersOptions = useMemo(
    () =>
      (loadPartnersAction.result || []).map((partner) => ({
        label: partner.name,
        value: partner.id,
      })),
    [loadPartnersAction.result]
  );

  /*
     Add beneficiary selector
     * */
  const updatedPartnerDetailsLayout = useMemo(() => {
    const modifiedLayout: ContainerDesc = JSON.parse(
      JSON.stringify(clientDetailsLayout)
    );
    const origBeneficiariesLayout =
      clientDetailsLayout.content[clientDetailsLayout.content.length - 1];
    const beneficiariesLayout: ContainerDesc = modifiedLayout.content[
      modifiedLayout.content.length - 1
    ] as ContainerDesc;
    const newPartners = partnersOptions.unshift({
      label: "+ " + t("createNew"),
      value: -1,
    });

    const favouriteBeneficiariesLabel =
      tradeFinance.tradeFinanceType === "tradefin/receivedGuarantee"
        ? "tradeFinance.favouritePartners"
        : isCollection(tradeFinance)
        ? "collection.favouriteBeneficiaries"
        : "tradeFinance.favouriteBeneficiaries";

    const modifyExistingBeneficiaryLabel =
      tradeFinance.tradeFinanceType === "tradefin/receivedGuarantee"
        ? "tradeFinance.modifyExistingPartner"
        : isCollection(tradeFinance)
        ? "collection.modifyExistingBeneficiary"
        : "tradeFinance.modifyExistingBeneficiary";

    beneficiariesLayout.type = ContainerType.VERTICAL;
    const checkBoxes = [
      {
        id: "favouriteBeneficiary",
        type: ContainerType.FORM,
        collapsible: CollapsibleState.DISABLED,
        content: [
          {
            id: "favouriteBeneficiary",
            type: FieldType.select,
            options: partnersOptions,
            label: t(favouriteBeneficiariesLabel),
            placeholder: t("tradeFinance.favouriteBeneficiaries"),
            disabled: isAmendment(tradeFinance),
            isClearable: true,
            onClearValue: clearPartnerFields,
          },
        ],
      },
      {
        id: "modifyExistingBeneficiary",
        type: ContainerType.FORM,
        collapsible: CollapsibleState.DISABLED,
        content: [
          {
            id: "modifyExistingBeneficiary",
            type: FieldType.boolean,
            className: "modifyExistingBeneficiary",
            label: t(modifyExistingBeneficiaryLabel),
          },
        ],
      },
    ];
    beneficiariesLayout.content = [
      {
        id: "favouriteBeneficiaries",
        type: ContainerType.HORIZONTAL,
        content: checkBoxes,
      },
      { ...origBeneficiariesLayout, label: undefined },
    ] as ContainerDesc[];

    return modifiedLayout;
  }, [clientDetailsLayout, loadPartnersAction.result, isEditEnabled]);

  const isOutgoingGuaranteeAmendment =
    isGuarantee(tradeFinance) &&
    isOutgoingTradeFinance(tradeFinance) &&
    isAmendment(tradeFinance);

  const isOutgoingLetterOfCreditAmendment =
    isLetterOfCredit(tradeFinance) &&
    isOutgoingTradeFinance(tradeFinance) &&
    isAmendment(tradeFinance);

  const isLetterOfCreditCancel =
    isLetterOfCredit(tradeFinance) && isCancelEvent(tradeFinance);

  const updatedRequestDetailsLayout = useMemo(() => {
    let modifiedLayout =
      isOutgoingGuaranteeAmendment ||
      isOutgoingLetterOfCreditAmendment ||
      isLetterOfCreditCancel
        ? JSON.parse(JSON.stringify(requestDetailsLayout))
        : requestDetailsLayout;

    const isIssuedGuarantee =
      tradeFinance.tradeFinanceType === "tradefin/IssuedGuarantee";

    const isOutgoingStandbyBG =
      tradeFinance.tradeFinanceType === "tradefin/outgoingStandbyBG";

    if (isOutgoingStandbyBG && isAmendment(tradeFinance)) {
      const instructionContainer = findContainerByField(
        modifiedLayout,
        "instruction"
      );

      if (instructionContainer) {
        let amendmentDetailsField = getAmendmentDetailsField(t);
        instructionContainer.content = [
          amendmentDetailsField,
          ...instructionContainer.content,
        ];
      }
    } else if (isIssuedGuarantee && isAmendment(tradeFinance)) {
      const narrativeContainer = findContainerByField(
        modifiedLayout,
        "narrative"
      );

      if (narrativeContainer) {
        let amendmentDetailsField = getAmendmentDetailsField(t);
        narrativeContainer.content = [
          amendmentDetailsField,
          ...narrativeContainer.content,
        ];
      }
    } else if (isOutgoingLetterOfCreditAmendment) {
      const amountContainer = findContainerByField(modifiedLayout, "amount");
      const decimals = getCurrencyDecimals(userStatus, form.values.currency);
      if (amountContainer) {
        const newFields: FieldProps[] = [
          {
            id: "event.increaseAmount",
            type: FieldType.decimal,
            label: t("tradeFinance.increaseAmount"),
            decimals: decimals,
          } as any as FieldProps,
          {
            id: "event.decreaseAmount",
            type: FieldType.decimal,
            label: t("tradeFinance.decreaseAmount"),
            decimals: decimals,
          } as any as FieldProps,
        ];

        insertFieldsAfter(amountContainer, "amount", newFields);
      }
    }

    let infoContainer = findContainerByField(
      modifiedLayout,
      "frameAgreementComment"
    );

    if (infoContainer) {
      const frameAgreementNo1 = {
        id: "clientDetails.frameAgreementNo1",
        type: FieldType.text,
        label: t("tradeFinance.frameAgreementNumber"),
        help: {
          en: t("tradeFinance.frameAgreementNoHelp", { lng: "en" }),
          cz: t("tradeFinance.frameAgreementNoHelp", { lng: "cz" }),
        } as Univ,
        disabled: true,
      } as FieldProps;

      insertFieldsAfter(infoContainer, "frameAgreementComment", [
        frameAgreementNo1,
      ]);
    }

    if (isLetterOfCredit(tradeFinance) && isAmendment(tradeFinance)) {
      if (infoContainer) {
        const amendmentFields: FieldProps[] = [
          {
            id: "event.amendmentChargePayableBy",
            codeBaseId: "tradefin/amendmentChargePayableBy",
            label: t("tradeFinance.amendmentChargePayableBy"),
            type: FieldType.codebase,
          } as any,
          {
            id: "event.amendmentChargePayableByOther",
            type: FieldType.text,
            label: t("tradeFinance.amendmentChargePayableByOther"),
          } as FieldProps,
          {
            id: "event.otherInstructionEvent",
            type: FieldType.textArea,
            label: t("tradeFinance.otherInstructionsEvent"),
          } as FieldProps,
        ];

        insertFieldsAfter(infoContainer, "instruction", amendmentFields);
      }
    }

    if (
      (isOutgoingStandBy(tradeFinance) ||
        tradeFinance.tradeFinanceType === "tradefin/outgoingStandbyLC") &&
      isAmendment(tradeFinance)
    ) {
      if (infoContainer) {
        const amendmentFields: FieldProps[] = [
          {
            id: "event.otherInstructionEvent",
            type: FieldType.textArea,
            label: t("tradeFinance.otherInstructionsEvent"),
          } as FieldProps,
        ];

        insertFieldsAfter(infoContainer, "instruction", amendmentFields);
      }
    }

    if (isLetterOfCreditCancel) {
      if (infoContainer) {
        const otherInstructionEventField = {
          id: "event.otherInstructionEvent",
          type: FieldType.textArea,
          required:
            tradeFinance.event?.eventDescRef &&
            tradeFinance.event?.eventDescRef.includes("cancelEvent"),
          label: t("tradeFinance.portalOtherInstructionEvent"),
        } as FieldProps;

        insertFieldsAfter(infoContainer, "clientDetails.frameAgreementNo1", [
          otherInstructionEventField,
        ]);
      }
    }

    return modifiedLayout;
  }, [requestDetailsLayout, isOutgoingGuaranteeAmendment]);

  const origFields = useMemo(
    () =>
      Object.values([
        ...collectFields(updatedPartnerDetailsLayout),
        ...collectFields(updatedRequestDetailsLayout),
      ]).reduce(
        (res, field) => Object.assign(res, { [field!.id]: { ...field } }),
        {} as FieldMap<TradeFinanceDetail>
      ),
    [updatedPartnerDetailsLayout, requestDetailsLayout]
  );

  const loadClausesAction = useLoadingAction(() => {
    if (isEditEnabled) {
      return TFApi.findActiveClauses(user!);
    }
  }, [isEditEnabled]);

  //TODO
  function runRequestDetailsUIRules(fieldProps: {
    [K in keyof TradeFinanceDetail]: FieldProps;
  }): { [K in keyof TradeFinanceDetail]: FieldProps } {
    if (!form.values || Object.keys(form.values).length === 0) {
      return fieldProps;
    }
    if (
      form.values.favouriteBeneficiary &&
      form.values.favouriteBeneficiary === -1
    ) {
      setNewPartnerOption(true);
      fieldProps.modifyExistingBeneficiary.className = "hidden";
    }

    if (form.values.modifyExistingBeneficiary) {
      fieldProps.favouriteBeneficiary.disabled = true;
    }

    if (fieldProps.modifyExistingBeneficiary && isAmendment(tradeFinance)) {
      fieldProps.modifyExistingBeneficiary.className = "hidden";
    }

    const amendmentChargePayableBy: FieldProps = (fieldProps as any)[
      "event.amendmentChargePayableBy"
    ] as FieldProps;

    const amendmentChargePayableByOther: FieldProps = (fieldProps as any)[
      "event.amendmentChargePayableByOther"
    ] as FieldProps;

    if (amendmentChargePayableBy) {
      amendmentChargePayableByOther.className = "hidden";

      if (form.values.event?.amendmentChargePayableBy === "OTHR") {
        amendmentChargePayableByOther.className = "";
      }

      if (
        form.values.event?.amendmentChargePayableBy !== "OTHR" &&
        form.values.event?.amendmentChargePayableByOther
      ) {
        amendmentChargePayableByOther.setValue(undefined);
      }
    }

    //TODO
    function setFieldError(field: string, error: string) {
      setTimeout(() => form.setFieldError(field, error), 0);
    }

    if (fieldProps.dateOfExpiry) {
      (fieldProps.dateOfExpiry as any as DateFieldProps).minDate = today
        .local(true)
        .toDate();
    }

    //TODO
    function updateSwiftcodeField(
      fieldName: string,
      onSelected: (partner: TradeFinancePartner | null | undefined) => void
    ) {
      const swiftCodeFieldProps = (fieldProps as any)[
        fieldName
      ] as SwiftCodeFieldProps;
      if (swiftCodeFieldProps) {
        (swiftCodeFieldProps as any as FieldProps).type = FieldType.swiftCode;
        swiftCodeFieldProps.onSelected = onSelected;
      }
    }

    if (fieldProps.otherPartnerBankName) {
      fieldProps.otherPartnerBankName.disabled = true;
    }

    if (fieldProps.otherPartnerBankSWIFTCode && isAmendment(tradeFinance)) {
      fieldProps.otherPartnerBankSWIFTCode.required = false;
    }

    updateSwiftcodeField("otherPartnerBankSWIFTCode", (partner) => {
      if (partner) {
        form.setFieldValue("otherPartnerBankName", partner.bankName);
        if (!isCollection(tradeFinance)) {
          form.setFieldValue(
            "otherPartnerNonDefinedAddress",
            partner.swiftAddress
          );
        }
      }
    });

    if (isOutgoingStandBy(tradeFinance)) {
      if (fieldProps.applicableRules) {
        const appRules = fieldProps.applicableRules as SelectFieldProps;
        filterSelectOptions(appRules, (val) => val !== "oTHR");
      }

      if (isAmendment(tradeFinance)) {
        //setRequiredAndVisible(fieldProps.instruction, false)
        fieldProps.instruction.className = "hidden";
      }
    }

    if (isLetterOfCredit(tradeFinance)) {
      const values: LetterOfCredit = form.values as unknown as LetterOfCredit;
      const locFields = fieldProps as unknown as {
        [K in keyof LetterOfCredit]: FieldProps;
      };

      if (
        ["atSight", "mixed", "negotiationAtSight"].includes(
          values.paymentMethod
        )
      ) {
        locFields.daysOfDeferment.hidden = true;
        locFields.deferredPaymentDetails.hidden = true;
      }

      if (locFields.accountNumberFee) {
        locFields.accountNumberFee.required = false;
      }

      if (locFields.mixedPaymentDetails) {
        locFields.mixedPaymentDetails.hidden =
          form.values.paymentMethod !== "mixed";
      }

      if (
        isImportLetterOfCredit(tradeFinance) &&
        !(isAmendment(tradeFinance!) || isCancelEvent(tradeFinance!))
      ) {
        if (
          locFields.latestDateOfShipment &&
          !locFields.latestDateOfShipment.error
        ) {
          if (
            values.latestDateOfShipment &&
            moment(values.latestDateOfShipment).isAfter(
              moment(values.dateOfExpiry)
            )
          ) {
            setFieldError(
              "latestDateOfShipment",
              t("tradeFinance.errors.latestDateOfShipment_expr")
            );
          }
        }
      }

      if (isOutgoingTradeFinance(tradeFinance) && isAmendment(tradeFinance)) {
        locFields.amount.disabled = true;
        locFields.currency.disabled = true;
      }

      if (isAmendment(tradeFinance)) {
        //setRequiredAndVisible(locFields.instruction, false)
        fieldProps.instruction.className = "hidden";
      }

      if (
        isOutgoingTradeFinance(tradeFinance) &&
        locFields.applicableRules &&
        !isAmendment(tradeFinance)
      ) {
        locFields.applicableRules.disabled = true;
      }

      if (
        isOutgoingTradeFinance(tradeFinance) &&
        locFields.applicableRules &&
        isAmendment(tradeFinance)
      ) {
        form.setFieldValue("applicableRules", "uCPLATESTVERSION");
        locFields.applicableRules.disabled = true;
      }

      const paymentMethod = fieldProps.paymentMethod as SelectFieldProps;

      if (
        tradeFinance.tradeFinanceType === "tradefin/importLC" ||
        tradeFinance.tradeFinanceType === "tradefin/outgoingStandbyLC"
      ) {
        filterSelectOptions(
          paymentMethod,
          (val) => val !== "acceptance" && val !== "negotiation"
        );
      }
    } else if (isCollection(tradeFinance)) {
      if (isOutgoingTradeFinance(tradeFinance)) {
        const values = form.values as unknown as Collection;
        const collFields = fieldProps as unknown as {
          [K in keyof Collection]: FieldProps;
        };

        if (values.conditionsOfDocumentDelivery === "acceptance") {
          setRequiredAndVisible(collFields.dueDateCalcDetails, true);
          setRequiredAndVisible(collFields.acceptanceDueDate, true);
        } else {
          if (collFields.dueDateCalcDetails) {
            collFields.dueDateCalcDetails.hidden = true;
          }
          if (collFields.acceptanceDueDate) {
            collFields.acceptanceDueDate.hidden = true;
          }
        }

        //2
        if (values.conditionsOfDocumentDelivery === "freeOfPayment") {
          collFields.protestNonAcceptance.hidden = true;
          collFields.protestNonPayment.hidden = true;
          collFields.tracePayment.hidden = true;
          collFields.partialPayment.hidden = true;
        }

        //3
        collFields.partialPayment.hidden =
          values.conditionsOfDocumentDelivery !== "acceptance";

        //4
        if (
          !collFields.acceptanceDueDate.error &&
          values.acceptanceDueDate &&
          moment(values.acceptanceDueDate).isBefore(today)
        ) {
          setFieldError(
            "acceptanceDueDate",
            t("tradeFinance.errors.acceptanceDueDate_future")
          );
        }

        //5
        collFields.dueDateCalcDetails.disabled = !!values.acceptanceDueDate;
        if (collFields.dueDateCalcDetails.disabled) {
          collFields.dueDateCalcDetails.required = false;
        }

        //6
        collFields.acceptanceDueDate.disabled = !!values.dueDateCalcDetails;
        if (collFields.acceptanceDueDate.disabled) {
          collFields.acceptanceDueDate.required = false;
        }
      }
    } else if (isGuarantee(tradeFinance)) {
      const values = form.values as unknown as Guarantee;
      const guaranteeFields = fieldProps as unknown as {
        [K in keyof Guarantee]: FieldProps;
      };

      const guaranteeMainType = values.guaranteeMainType;
      const communicationMethod = values.communicationMethod;

      const isIssuedGuarantee =
        tradeFinance.tradeFinanceType === "tradefin/IssuedGuarantee";
      const isCounterGuarantee = guaranteeMainType === "counterGuarantee";
      const isAdvisedGuarantee = guaranteeMainType === "advisedGuarantee";
      const isPromise = guaranteeMainType === "promise";

      updateSwiftcodeField("finalGuaranteeBankSwift", (partner) => {
        if (partner) {
          let isBankChanged =
            (form.values as any as Guarantee).finalGuaranteeBankSwift !==
            partner.swiftCode;
          form.setFieldValue(
            "finalGuaranteeBankName",
            (form.values as any as Guarantee).finalGuaranteeBankName &&
              !isBankChanged
              ? (form.values as any as Guarantee).finalGuaranteeBankName
              : partner.bankName
          );
        }
      });

      updateSwiftcodeField("advisingBankSwift", (partner) => {
        if (partner) {
          let isBankChanged =
            (form.values as any as Guarantee).advisingBankSwift !==
            partner.swiftCode;
          form.setFieldValue(
            "advisingBankName",
            (form.values as any as Guarantee).advisingBankName && !isBankChanged
              ? (form.values as any as Guarantee).advisingBankName
              : partner.bankName
          );
        }
      });

      if (
        prevGuaranteeMainType !== guaranteeMainType &&
        loadPartnersAction.result
      ) {
        const favBeneficiaryId = parseInt(form.values.favouriteBeneficiary);
        const partner: Partner | undefined = loadPartnersAction.result.find(
          (p) => p.id === favBeneficiaryId
        );

        if (partner) {
          if (isAdvisedGuarantee) {
            form.setFieldValue(
              "advisingBankSwift",
              (form.values as any as Guarantee).advisingBankSwift
                ? (form.values as any as Guarantee).advisingBankSwift
                : partner.bankSwiftCode
            );
            form.setFieldValue(
              "advisingBankName",
              (form.values as any as Guarantee).advisingBankName
                ? (form.values as any as Guarantee).advisingBankName
                : partner.bankName
            );
          } else if (isCounterGuarantee) {
            form.setFieldValue(
              "finalGuaranteeBankSwift",
              (form.values as any as Guarantee).finalGuaranteeBankSwift
                ? (form.values as any as Guarantee).finalGuaranteeBankSwift
                : partner.bankSwiftCode
            );
            form.setFieldValue(
              "finalGuaranteeBankName",
              (form.values as any as Guarantee).finalGuaranteeBankName
                ? (form.values as any as Guarantee).finalGuaranteeBankName
                : partner.bankName
            );
          }
        }
      }

      if (
        (!isCounterGuarantee &&
          values.typeOfCertificate === "standardWordingOfFinalBank") ||
        (isCounterGuarantee && values.typeOfCertificate === "kBStandard")
      ) {
        guaranteeFields.typeOfCertificate.setValue(null);
      }

      if (guaranteeFields.finalExpiryDate) {
        (guaranteeFields.finalExpiryDate as any as DateFieldProps).minDate =
          today.local(true).toDate();
      }

      if (guaranteeFields.counterGuaranteeDateOfExpiry) {
        (
          guaranteeFields.counterGuaranteeDateOfExpiry as any as DateFieldProps
        ).minDate = today.local(true).toDate();
      }

      if (isIssuedGuarantee && isPromise) {
        guaranteeFields.finalExpiryDate.required = true;
      }

      //https://jira.appello.eu/browse/KBTF-1860
      if (!isOutgoingStandBy(tradeFinance)) {
        //guaranteeMainType: please hide guaranteeIssuedOnBehalfOfCounterGuarantee codebase element
        filterSelectOptions(
          guaranteeFields.guaranteeMainType,
          (val) => val !== "guaranteeIssuedOnBehalfOfCounterGuarantee"
        );

        //if guaranteeMainType = counterGuarantee,for typeOfCertificate field set visible standardWordingOfFinalBank codebase element and hide kBStandard, else hide standardWordingOfFinalBank and set visible kBStandard
        filterSelectOptions(guaranteeFields.typeOfCertificate, (val) =>
          isCounterGuarantee
            ? val !== "kBStandard"
            : val !== "standardWordingOfFinalBank"
        );

        //if guaranteeMainType = counterGuarantee, set visible deliveryLocalDetails and finalGuaranteeBankSwift and finalGuaranteeBankName and counterGuaranteeDateOfExpiry, else hide
        setVisible(guaranteeFields.delieveryLocalDetails, isCounterGuarantee);
        setVisible(guaranteeFields.finalGuaranteeBankSwift, isCounterGuarantee);
        setVisible(guaranteeFields.finalGuaranteeBankName, isCounterGuarantee);
        setVisible(
          guaranteeFields.counterGuaranteeDateOfExpiry,
          isCounterGuarantee
        );

        //if guaranteeMainType = counterGuarantee or (guaranteeMainType = advisedGuarantee and advisingBankCharges =debitToUs), set visible accountNumberFee, else hide
        setVisible(
          guaranteeFields.accountNumberFee,
          isCounterGuarantee ||
            (isAdvisedGuarantee && values.advisingBankCharges === "debitToUs")
        );

        //if guaranteeMaintype = advisedGuarantee, set visible advisingBankSwift and advisingBankName and advisingBankCharges, else hide
        setVisible(guaranteeFields.advisingBankSwift, isAdvisedGuarantee);
        setVisible(guaranteeFields.advisingBankName, isAdvisedGuarantee);
        setVisible(guaranteeFields.advisingBankCharges, isAdvisedGuarantee);

        //if guaranteeMainType = promise, set visible finalExpiryDate, else hide
        setVisible(guaranteeFields.finalExpiryDate, isPromise);
      }
      //if languageOfCertificate = other, set visible languageOfCertificateOther, else hide languageOfCertificateOther
      setVisible(
        guaranteeFields.languageOfCertificateOther,
        values.languageOfCertificate === "other"
      );
      //if endUser = other, set visible endUserDetails, else hide endUserDetails
      setVisible(guaranteeFields.endUserDetails, values.endUser === "other");
      //if thirdPartyGuarantee=true set visible thirdPartyDetails, else hide thirdPartyDetails
      setVisible(guaranteeFields.thirdPartyDetails, values.thirdPartyguarantee);

      if (isAmendment(tradeFinance)) {
        //if guaranteeMainType = promise, set visible finalExpiryDate, else hide
        setVisible(guaranteeFields.finalExpiryDate, isPromise);
        //if guaranteeMainType = counterGuarantee, set visible delieveryLocalDetails and finalGuaranteeBankSwift and finalGuaranteeBankName and counterGuaranteeDateOfExpiry, else hide
        setVisible(guaranteeFields.delieveryLocalDetails, isCounterGuarantee);
        setVisible(guaranteeFields.finalGuaranteeBankSwift, isCounterGuarantee);
        setVisible(guaranteeFields.finalGuaranteeBankName, isCounterGuarantee);
        setVisible(
          guaranteeFields.counterGuaranteeDateOfExpiry,
          isCounterGuarantee
        );
        //if guaranteeMainType = counterGuarantee or (guaranteeMainType = advisedGuarantee and advisingBankCharges =debitToUs), set visible accountNumberFee, else hide
        setRequiredAndVisible(
          guaranteeFields.accountNumberFee,
          isCounterGuarantee ||
            (isAdvisedGuarantee && values.advisingBankCharges === "debitToUs")
        );
        //if guaranteeMaintype = advisedGuarantee, set visible advisingBankSwift and advisingBankName and advisingBankCharges, else hide
        setVisible(guaranteeFields.advisingBankSwift, isAdvisedGuarantee);
        setVisible(guaranteeFields.advisingBankName, isAdvisedGuarantee);
        setVisible(guaranteeFields.advisingBankCharges, isAdvisedGuarantee);

        if (isIssuedGuarantee && isCounterGuarantee) {
          setVisible(guaranteeFields.communicationMethod, false);
        }
      }

      if (isOutgoingTradeFinance(tradeFinance) && isAmendment(tradeFinance)) {
        setVisible(guaranteeFields.cDeliveryOfamendment, isCounterGuarantee);

        setEditable(guaranteeFields.finalGuaranteeBankSwift, false);
        setEditable(guaranteeFields.finalGuaranteeBankName, false);
        setEditable(guaranteeFields.advisingBankSwift, false);
        setEditable(guaranteeFields.advisingBankName, false);
        setEditable(
          guaranteeFields.advisingBankCharges,
          isIssuedGuarantee && isAdvisedGuarantee && isEditEnabled
        );

        if (isAdvisedGuarantee) {
          setVisible(guaranteeFields.dispatchDetails, false);
          setVisible(guaranteeFields.communicationMethod, false);
        } else {
          setRequiredAndVisible(
            guaranteeFields.dispatchDetails,
            [
              "PersonallyInBankGuaranteeUnit",
              "CourierToBeneficiary",
              "other",
              "ElectronicForm",
            ].includes(communicationMethod)
          );
        }

        guaranteeFields.currency.disabled = true;
      } else {
        //1
        setRequiredAndVisible(
          guaranteeFields.subtypeDesc,
          values.subtype === "OTHER"
        );

        //2
        setRequiredAndVisible(
          guaranteeFields.communicationMethod,
          !(isCounterGuarantee || isAdvisedGuarantee)
        );

        //3
        setRequiredAndVisible(
          guaranteeFields.counterGuaranteeDateOfExpiry,
          isCounterGuarantee
        );

        //4
        if (isCounterGuarantee || isAdvisedGuarantee) {
          setRequiredAndVisible(guaranteeFields.dispatchDetails, false);
        } else {
          if (
            communicationMethod === "other" ||
            communicationMethod === "ElectronicForm" ||
            communicationMethod === "CourierToBeneficiary"
          ) {
            setRequiredAndVisible(guaranteeFields.dispatchDetails, true);
          } else {
            setRequiredAndVisible(guaranteeFields.dispatchDetails, false);
          }
        }

        //5
        setRequiredAndVisible(
          guaranteeFields.endUserDetails,
          values.endUser === "otherEndUser"
        );

        if (guaranteeFields.communicationMethod) {
          //6
          (guaranteeFields.communicationMethod as SelectFieldProps).options = (
            guaranteeFields.communicationMethod as SelectFieldProps
          ).options.filter(
            (opt) =>
              ![
                "swift",
                "ReceivedByClient",
                "ReceivedByApplicantBank",
              ].includes(opt.value as string)
          );
        }
      }

      if (
        isIssuedGuarantee &&
        communicationMethod === "PersonallyInBankGuaranteeUnit"
      ) {
        setRequiredAndVisible(guaranteeFields.dispatchDetails, true);
      }
    }

    const frameAgreementComment =
      fieldProps.frameAgreementComment as SelectFieldProps;

    if (frameAgreementComment) {
      const guaranteeMainType = isGuarantee(tradeFinance)
        ? (form.values as unknown as Guarantee).guaranteeMainType
        : null;

      const isIssuedGuarantee =
        tradeFinance.tradeFinanceType === "tradefin/IssuedGuarantee";

      const isLc = tradeFinance.tradeFinanceType === "tradefin/importLC";

      const isStandBy =
        tradeFinance.tradeFinanceType === "tradefin/outgoingStandbyBG" ||
        tradeFinance.tradeFinanceType === "tradefin/outgoingStandbyLC";

      const isAmendmentEvent = isAmendment(tradeFinance);
      const isCancelEvBool = isCancelEvent(tradeFinance);

      const client = template
        ? user?.defaultCompany
        : tradeFinance.clientDetails;

      let frameAgreementCommentOptions = frameAgreementComment.options.map(
        (opt) => opt.value
      );

      if (
        isIssuedGuarantee &&
        guaranteeMainType !== "promise" &&
        !isAmendmentEvent
      ) {
        frameAgreementCommentOptions = ["newBGwithFA", "newBGwithoutFA"];
      } else if (
        isIssuedGuarantee &&
        guaranteeMainType !== "promise" &&
        isAmendmentEvent
      ) {
        frameAgreementCommentOptions = ["amendBGwithFA", "amendBGwithoutFA"];
      } else if (
        isIssuedGuarantee &&
        guaranteeMainType === "promise" &&
        !isAmendmentEvent
      ) {
        frameAgreementCommentOptions = [
          "newPromiseWithFA",
          "newPromiseWithoutFA",
        ];
      } else if (
        isIssuedGuarantee &&
        guaranteeMainType === "promise" &&
        isAmendmentEvent
      ) {
        frameAgreementCommentOptions = [
          "amendPromiseWithFA",
          "amendPromiseWithoutFA",
        ];
      } else if ((isLc || isStandBy) && !isAmendmentEvent) {
        frameAgreementCommentOptions = [
          "newLcStandbyWithFA",
          "newLcStandbyWithoutFA",
        ];
      } else if ((isLc || isStandBy) && isAmendmentEvent) {
        frameAgreementCommentOptions = [
          "amendLcStandbyWithFA",
          "amendLcStandbyWithoutFA",
        ];
      }

      if ((isLc || isStandBy) && isCancelEvBool) {
        frameAgreementCommentOptions = [
          "amendLcStandbyWithFA",
          "amendLcStandbyWithoutFA",
        ];
      }

      if (client && !client.guaranteesUnderFa) {
        let hiddenOptions = [
          "amendBGwithFA",
          "amendPromiseWithFA",
          "newBGwithFA",
          "newPromiseWithFA",
        ];
        frameAgreementCommentOptions = frameAgreementCommentOptions.filter(
          (opt) => typeof opt === "string" && !hiddenOptions.includes(opt)
        );
      }
      if (client && !client.importLCUnderFa && isLc) {
        let hiddenOptions = ["amendLcStandbyWithFA", "newLcStandbyWithFA"];
        frameAgreementCommentOptions = frameAgreementCommentOptions.filter(
          (opt) => typeof opt === "string" && !hiddenOptions.includes(opt)
        );
      }

      frameAgreementComment.options = frameAgreementComment.options.filter(
        (opt) => frameAgreementCommentOptions.includes(opt.value)
      );

      //TODO
      if (isAmendmentEvent || isCancelEvBool) {
        let underFrameAgreement = tradeFinance.underFrameAgreement;

        // Amendment frame agreement rules
        if (tradeFinance.tradeFinanceType === "tradefin/importLC") {
          form.setFieldValue(
            "frameAgreementComment",
            underFrameAgreement
              ? "amendLcStandbyWithFA"
              : "amendLcStandbyWithoutFA"
          );
        } else if (isIssuedGuarantee && guaranteeMainType === "promise") {
          form.setFieldValue(
            "frameAgreementComment",
            underFrameAgreement ? "amendPromiseWithFA" : "amendPromiseWithoutFA"
          );
        } else if (isIssuedGuarantee && guaranteeMainType !== "promise") {
          form.setFieldValue(
            "frameAgreementComment",
            underFrameAgreement ? "amendBGwithFA" : "amendBGwithoutFA"
          );
        } else if (isStandBy) {
          form.setFieldValue(
            "frameAgreementComment",
            underFrameAgreement
              ? "amendLcStandbyWithFA"
              : "amendLcStandbyWithoutFA"
          );
        } else if (isCancelEvBool) {
          //TODO
          frameAgreementCommentOptions = [
            "amendLcStandbyWithFA",
            "amendLcStandbyWithoutFA",
          ];
        }

        frameAgreementComment.disabled = true;
      }
      const mainTypeChanged = prevGuaranteeMainType !== guaranteeMainType;

      if (isFirstRender && tradeFinance.frameAgreementComment) {
        form.setFieldValue(
          "frameAgreementComment",
          tradeFinance.frameAgreementComment
        );
        setFirstRender(false);
      } else {
        if (
          (mainTypeChanged &&
            (!isAmendmentEvent || tradeFinance.event == null)) ||
          isFirstRender
        ) {
          if (isIssuedGuarantee && guaranteeMainType === "promise") {
            form.setFieldValue(
              "frameAgreementComment",
              client?.guaranteesUnderFa
                ? "newPromiseWithFA"
                : "newPromiseWithoutFA"
            );
          } else if (isIssuedGuarantee && guaranteeMainType !== "promise") {
            form.setFieldValue(
              "frameAgreementComment",
              client?.guaranteesUnderFa ? "newBGwithFA" : "newBGwithoutFA"
            );
          } else if (isLc && isFirstRender) {
            form.setFieldValue(
              "frameAgreementComment",
              client?.importLCUnderFa
                ? "newLcStandbyWithFA"
                : "newLcStandbyWithoutFA"
            );
          } else if (isStandBy) {
            form.setFieldValue(
              "frameAgreementComment",
              client?.standbyUnderFa
                ? "newLcStandbyWithFA"
                : "newLcStandbyWithoutFA"
            );
          }
        }
        setFirstRender(false);
      }

      // frameAgreementNo1 visibility rule
      const frameAgreementNo1: FieldProps = (fieldProps as any)[
        "clientDetails.frameAgreementNo1"
      ] as FieldProps;
      if (frameAgreementNo1) {
        if (template) {
          frameAgreementNo1.setValue(
            user?.defaultCompany?.frameAgreementNumber
          );
        }
        let indicatorValues = [
          "amendBGwithFA",
          "amendLcStandbyWithFA",
          "amendPromiseWithFA",
          "newBGwithFA",
          "newLcStandbyWithFA",
          "newPromiseWithFA",
        ];
        setVisible(
          frameAgreementNo1,
          indicatorValues.includes(form.values.frameAgreementComment)
        );
      }
    }

    const amountField = fieldProps.amount as NumberFieldProps;
    const currencyField = fieldProps.currency as FieldProps;

    if (amountField && currencyField) {
      amountField.decimals = getCurrencyDecimals(
        userStatus,
        currencyField.value
      );
    }

    if (isImportLetterOfCreditInCancelEventWaitingForClient(tradeFinance)) {
      Object.values(fieldProps).forEach((field) => {
        if (field && field.id !== "event.otherInstructionEvent") {
          field.disabled = true;
        }
      });
    }

    return fieldProps;
  }

  //TODO
  function updateBeneficiariesFields(fieldProps: {
    [K in keyof TradeFinanceDetail]: FieldProps;
  }): { [K in keyof TradeFinanceDetail]: FieldProps } {
    if (form.values.favouriteBeneficiary) {
      partnerFields.forEach((partnerFieldId) => {
        const partnerField = (fieldProps as any)[partnerFieldId];
        if (partnerField) {
          partnerField.disabled =
            !form.values.modifyExistingBeneficiary &&
            form.values.favouriteBeneficiary !== -1;
        }
      });

      fieldProps.otherPartnerSwiftAddress.required = true;
    }

    if (
      !form.values.favouriteBeneficiary &&
      !form.values.otherPartnerName &&
      !createNewPartnerOption
    ) {
      partnerFields.forEach((partnerFieldId) => {
        const partnerField = (fieldProps as any)[partnerFieldId];
        if (partnerField) {
          partnerField.hidden = true;
        }
      });
      fieldProps.modifyExistingBeneficiary.className = "hidden";
    }

    const favBeneficiaryId = parseInt(form.values.favouriteBeneficiary);
    //beneficiary changed
    let isBeneficiaryChanged =
      favBeneficiaryId !== prevFavouriteBeneficiary.current;
    if (isBeneficiaryChanged) {
      if (form.values.favouriteBeneficiary === -1) {
        clearPartnerFields(form);
      }
      if (loadPartnersAction.result) {
        setNewPartnerOption(false);
        const partner: Partner | undefined = loadPartnersAction.result.find(
          (p) => p.id === favBeneficiaryId
        );

        if (
          partner &&
          (!tradeFinance.event || isEventWaitingForClient(tradeFinance)) &&
          !isFirstLoading
        ) {
          const partnerFieldValues: any = {};
          for (let [tfField, partnerField] of Object.entries(
            partnerFieldMapping
          )) {
            partnerFieldValues[tfField] = (partner as any)[partnerField];
          }
          form.setValues({ ...form.values, ...partnerFieldValues });
        }

        if (
          form.values.otherPartnerBankPhoneNr &&
          isCollection(tradeFinance) &&
          partner &&
          (!partner.bankPhoneNr || partner.bankPhoneNr !== undefined)
        ) {
          form.setFieldValue("otherPartnerBankPhoneNr", partner.bankPhoneNr);
        }
        //prefill some fields in transaction details section only in case of guarantee
        if (partner && isGuarantee(tradeFinance)) {
          const guaranteeMainType = (form.values as any as Guarantee)
            .guaranteeMainType;
          if (guaranteeMainType === "counterGuarantee") {
            form.setFieldValue(
              "finalGuaranteeBankSwift",
              (form.values as any as Guarantee).finalGuaranteeBankSwift &&
                !isBeneficiaryChanged
                ? (form.values as any as Guarantee).finalGuaranteeBankSwift
                : partner.bankSwiftCode
            );
            form.setFieldValue(
              "finalGuaranteeBankName",
              (form.values as any as Guarantee).finalGuaranteeBankName &&
                !isBeneficiaryChanged
                ? (form.values as any as Guarantee).finalGuaranteeBankName
                : partner.bankName
            );
          } else if (guaranteeMainType === "advisedGuarantee") {
            form.setFieldValue(
              "advisingBankSwift",
              (form.values as any as Guarantee).advisingBankSwift &&
                !isBeneficiaryChanged
                ? (form.values as any as Guarantee).advisingBankSwift
                : partner.bankSwiftCode
            );
            form.setFieldValue(
              "advisingBankName",
              (form.values as any as Guarantee).advisingBankName &&
                !isBeneficiaryChanged
                ? (form.values as any as Guarantee).advisingBankName
                : partner.bankName
            );
          }
        }

        if (form.values.modifyExistingBeneficiary) {
          form.setFieldValue("modifyExistingBeneficiary", false);
        }
      }
      setFirstLoading(false);
    }

    prevFavouriteBeneficiary.current = favBeneficiaryId;

    if (isOutgoingTradeFinance(tradeFinance) && isAmendment(tradeFinance)) {
      collectFields(clientDetailsLayout).forEach((field) => {
        const fieldToDisable: FieldProps = (fieldProps as any)[field.id];
        if (fieldToDisable) {
          fieldToDisable.disabled = true;
        }
      });
    }

    return fieldProps;
  }

  useEffect(() => {
    const enhancedFields = {} as FieldMap<TradeFinanceDetail>;
    const event = tradeFinance.event?.eventDescRef.split("/").pop();

    if (!Object.keys(origFields).length || !Object.keys(form.values).length) {
      return;
    }
    Object.keys(origFields).forEach((fieldId) => {
      const field = (origFields as any)[fieldId];
      const fieldProps = fieldId.startsWith("event.")
        ? getEventFieldProps(form, field.id, field.label as any)
        : getFieldProps(form, field.id as any, field.label as any);
      (enhancedFields as any)[fieldId] = Object.assign({}, field, fieldProps, {
        disabled: field.disabled || form.isSubmitting,
        lang: lang,
      });
    });

    Object.values(enhancedFields).forEach((field) => {
      if (field) {
        if (!isEditEnabled) {
          field.disabled = true;
        }

        if (template) {
          field.required = false;
        }

        if (
          (field.type === FieldType.text ||
            field.type === FieldType.textArea ||
            field.type === FieldType.swift) &&
          loadClausesAction.result &&
          isEditEnabled
        ) {
          const actionResult = loadClausesAction.result;
          const clausesForField = actionResult.filter((clause) => {
            let dealTypeMatches = clause.dealType?.includes(
              businessEntityDescriptorRefName(
                tradeFinance.businessEntityDescriptorId
              )
            );
            let transactionTypeMatches =
              !clause.transactionType ||
              (!tradeFinance.event &&
                clause.transactionType?.includes("completeRequestEvent")) ||
              (event && clause.transactionType.includes(event as string));
            return (
              dealTypeMatches &&
              transactionTypeMatches &&
              clause.fieldId?.includes(field.id)
            );
          });

          if (clausesForField.length > 0) {
            if (field.type === FieldType.textArea) {
              field.type = FieldType.textAreWithClauses;
              (field as TextAreaWithClausesProps).rows = 1;
            } else if (field.type === FieldType.text) {
              field.type = FieldType.textWithClauses;
            }

            (
              field as
                | TextAreaWithClausesProps
                | TextWithClausesProps
                | SwiftFieldProps
            ).clauses = clausesForField;
          }
        }
      }
    });

    const newFormFields = runRequestDetailsUIRules(
      updateBeneficiariesFields(enhancedFields)
    );
    setFormFields(newFormFields);
  }, [
    updatedPartnerDetailsLayout,
    requestDetailsLayout,
    form.values,
    form.isSubmitting,
    form.errors,
    loadClausesAction.result,
  ]);

  useEffect(() => {
    Object.values(formFields).forEach((field: any) => {
      if (field && field.hidden && field.value !== null && !field.disabled) {
        form.setFieldValue(field.id, null);
      }
    });
  }, [formFields]);

  return [updatedPartnerDetailsLayout, updatedRequestDetailsLayout];
}
