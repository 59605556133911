import { ContainerDesc, FormValues } from "./components/Form/types";
import { Moment } from "moment";

export type Language = "en" | "cz";

export type Univ = Partial<{ [K in Language]: string }>;

export type CurrencyId = "USD" | "EUR" | "HUF";

export type Currency = {
  id: CurrencyId;
  label: string;
};

export type Data = { [key: string]: any };

export interface RequestStats {
  totalSum: number;
  draft: number;
  waitingForInfo: number;
  pending: number;
  done: number;
}

export interface TradeFinanceRequestStats {
  totalSum: number;
  guaranteeSum: number;
  letterOfCreditSum: number;
  collectionSum: number;
}

export type Stats = {
  ongoingTransactions: number;
  signatureRequired: number;
  activeGuarantees: number;
  activeLC: number;
  activeCollection: number;
  standby: number;
  draft: number;
};

export type DashboardData = Stats;

export type UserStatus = {
  user: User;
  documents: TFDocument[];
  zeroDecimalCurrencies: string;
  threeDecimalCurrencies: string;
  importLCDocumentTemplate: string;
  exportLCDocumentTemplate: string;
  exportCollectionDocumentTemplate: string;
  serviceTemplate: string;
};

export interface Document {
  id: number;
  title: string;
  description: string;
  comment: string;
  date: number;
  documentType: string;
}

export interface TFDocument {
  id: number;
  name: string;
  url: string;
  fileName: string;
  description: string;
  date: number;
  file?: File | null;
  mandatory?: boolean;
  fileType: string;
  fileTypeUniv: Univ;
  uploadedByClient?: boolean;
  eventId: number | null;
  portalOnly: boolean;
  dmsId: string;
  generatedFromTemplate: boolean;
  createUser: string;
  createUserEmail: string;
  createUserPhone: string;
  lastModifiedUserUniv: Univ;
  lastModifiedUser: string;
  error?: string | null;
}

export type AccountInfo = {
  currency: string;
  amount: string;
  iban: string;
};

export type UnderWriter = {
  id?: Id;
  type: string;
  limit: number;
  event: string;
  numberOfGroupA: number;
  numberOfGroupB: number;
};

export type Underwriter = {
  underwriterId: number;
  underwriterName: string;
};

export interface Signature {
  id: number;
  transactionMainType: Univ;
  transactionEventType: Univ;
  referenceNumber: string;
  beneficiaryName: string;
  amount: number;
  date: Date;
  numberOfGroupA: string;
  numberOfGroupB: string;
  tradeFinanceId: number;
  eventId: number;
  currency: string;
  tradeFinanceDescriptorId: string;
  canSign: boolean;
}

export type User = {
  id: number;
  email: string;
  phoneNumber: string;
  phoneNumberContact: string;
  name: string;
  dontSendMeEmails?: boolean;
  lang: Language;
  profileImage?: string;
  profileType?: string;
  defaultCompany?: Company;
  initialDefaultCompany?: number;
  superUser: boolean;
  draftMaker: string[];
  noEmail: boolean;
  notificationToDo: NotificationAlertType;
  notificationSignature: NotificationAlertType;
  notificationInformation: NotificationAlertType;
  countryCode: string;
  countryCodeContact: string;
  preferredLanguage: string;
};

export type NotificationAlertType = "sms" | "email" | "smsAndEmail" | "none";

export type UserNotificationData = {
  signatureSMS: boolean;
  signatureEmail: boolean;
  toDoSMS: boolean;
  toDoEmail: boolean;
  infoSMS: boolean;
  infoEmail: boolean;
};

export interface Company extends HasId<number> {
  companyName: string;
  companyStatId: string;
  incorporationYear: Date;
  legalForm: string;
  nationalId: string;
  registrationDate: Date;
  registrationNumber: string;
  registeredOffice: string;
  taxResidency: string;
  taxationId: string;
  foreignTradeFinSpecName: string;
  foreignTradeFinSpecEmail: string;
  foreignTradeFinSpecPhone: string;
  fileNumber: string;
  frameAgreementNumber: string;
  importLCUnderFa: boolean;
  guaranteesUnderFa: boolean;
  standbyUnderFa: boolean;
}

export type Beneficiary = {
  id: Id;
  bankCity: string;
  bankCountry: string;
  bankCounty: string;
  bankName: string;
  bankStreet: string;
  bankStreetNumber: string;
  bankSwiftCode: string;
  bankZipCode: string;
  name: string;
  partnerCity: string;
  partnerCountry: string;
  partnerCounty: string;
  partnerRegistrationNumber: string;
  partnerStreet: string;
  partnerStreetNumber: string;
  partnerSwiftAddress: string;
  partnerZipCode: string;
  swiftCode: string;
  fullName: string;
  bankSwiftAddress: string;
  partnerAccountNumber: string;
  bankPhoneNr: string;
};

export type TradeFinanceType =
  | "tradefin/IssuedGuarantee"
  | "tradefin/receivedGuarantee"
  | "tradefin/importLC"
  | "tradefin/exportLC"
  | "tradefin/exportCollection"
  | "tradefin/importCollection"
  | "tradefin/outgoingStandbyLC"
  | "tradefin/incomingStandbyLC"
  | "tradefin/outgoingStandbyBG"
  | "tradefin/incomingStandbyBG";

export type TFEvent = {
  id: number;
  state: string;
  stateName: Univ;
  eventState: string;
  eventStateName: Univ;
  eventDescRef: string;
  eventDescRefName: Univ;
  originOfGoodsEvent: string;
  otherInstructionEvent: string;
  invoiceNumber: string;
  claimAmount: number;
  preCheckResult: string;
  increaseAmount: number;
  decreaseAmount: number;
  amendmentChargePayableBy: string;
  amendmentChargePayableByOther: string;
  amendmentDetails: string;
  orderDate: Date;
};

export type ListStats = {
  activated: number;
  drafts: number;
  expired: number;
  ongoing: number;
  signatureRequired: number;
  all: number;
  applications: number;
  amendments: number;
};

export interface TradeFinance extends HasId<string> {
  event?: TFEvent;
  claimData: ClaimData;
  state: string;
  stateName: Univ;
  referenceNo: string | null | undefined;
  tradeFinanceType: TradeFinanceType;
  cardDetails?: FormValues<any>;
  saveAsItNewBeneficiary: any;

  createTime: number;
  initiationAs: string;
  acceptedDocument: string;
  acceptanceDueDate: Date;
  accountNumberFee: string;
  accountNumberLoan: string;
  availableAmount: number;
  amount: number;
  amountPaid: number;
  applicableRules: string;
  balance: number;
  contractSignPlace: string;
  currency: string;
  detailsOfBaseTransaction: string;
  documentRequirements: string;
  documentRequirementCount: number;
  eventState: string;
  frameAgreementNumber: string;
  identifier: string;
  issueWith: string;
  issuerAddress: string;
  issuerBankName: string;
  issuerBankSWIFTCode: string;
  issuerCity: string;
  issuerCode: string;
  issuerCountry: string;
  issuerCounty: string;
  issuerName: string;
  issuerRegistrationNumber: string;
  issuerSWIFTCode: string;
  issuerStreet: string;
  issuerStreetNumber: string;
  languageOfCertificate: string;
  languageOfCertificateOther: string;
  originOfGoods: string;
  otherInstruction: string;
  otherPartnerAddressDesc: string;
  otherPartnerBankName: string;
  otherPartnerBankPhoneNr: string;
  otherPartnerBankSWIFTCode: string;
  otherPartnerCity: string;
  otherPartnerCode: string;
  otherPartnerCountry: string;
  otherPartnerCounty: string;
  otherPartnerName: string;
  otherPartnerRegistrationNumber: string;
  otherPartnerAccountNumber: string;
  otherPartnerSWIFTCode: string;
  otherPartnerStreet: string;
  otherPartnerStreetNumber: string;
  provisional: boolean;
  typeOfCertificate: string;
  underFrameAgreement: boolean;
  type: string;
  typeName: Univ;
  dateOfExpiry: Date;
  businessEntityDescriptorId: string;
  paymentMethod: string;
  instruction: string;
  communicationMethod: string;
  requiredDocuments: string;
  otherPartnerSwiftAddress: string;
  otherPartnerNonDefinedAddress: string;
  frameAgreementComment: string;
  otherAmendments: string;
  cOtherAmendments: string;
  signature?: Signature;
  clientDetails?: Client;
  lastMessageInfo?: string;
  externalIdentifier: string;
  favouriteBeneficiary: any;
  lastTime: number;
}

export interface Client extends HasId<number> {
  companyName: string;
  importLCUnderFa: boolean;
  guaranteesUnderFa: boolean;
  standbyUnderFa: boolean;
  frameAgreementNo1: string;
}

export interface Guarantee extends TradeFinance {
  baseOfTransaction: string;
  coveredWithCounterGuarantee: boolean;
  dateOfIssue: Date;
  detailsOfGuarantee: string;
  feeDebitAccount: string;
  indicativeDateOfExpiry: Date;
  mainGuaranteeType: string;
  subtype: string;
  subTypeName: Univ;
  typeOfApplicationGuarantee: string;
  undertaking: string;
  subtypeDesc: string;
  guaranteeMainType: string;
  counterGuaranteeDateOfExpiry: Date;
  dispatchDetails: string;
  endUserDetails: string;
  endUser: string;
  cDeliveryOfamendment: Date;
  finalExpiryDate: Date;
  localUndertakingTerms: string;
  cUndertakingTerms: string;
  delieveryLocalDetails: string;
  finalGuaranteeBankName: string;
  finalGuaranteeBankSwift: string;
  advisingBankCharges: string;
  advisingBankName: string;
  advisingBankSwift: string;
  thirdPartyguarantee: boolean;
  thirdPartyDetails: string;
}

export interface Collection extends TradeFinance {
  agentCommissionCurrency: string;
  beneficiaryOfAgentsCommission: string;
  closeType: string;
  collBankChrgsFor: string;
  commissionAmount: number;
  conditionsOfDocumentDelivery: string;
  delieverDocsAgainst: string;
  docDescription: string;
  docName: string;
  paymentComment: string;
  protestNonAcceptance: boolean;
  protestNonPayment: boolean;
  tracePayment: boolean;
  partialPayment: boolean;
  protestRequest: string;
  remBankChrgsFor: string;
  sendDocsVia: string;
  sortingNumberOfGivenDocuments: number;
  urgePayment: boolean;
  dueDateCalcDetails: string;
}

export interface LetterOfCredit extends TradeFinance {
  accountNumberOfCertifiedLoan: number;
  additionalAmountTitle: string;
  applicableRules: string;
  approvalWithCover: boolean;
  askedForCertification: boolean;
  authenticatedLC: boolean;
  backToBack: boolean;
  bankReceivedItAsTransfered: boolean;
  canBeNegotiated: boolean;
  certifiedRegisteredAmount: number;
  cessionB2B: string;
  charges: string;
  claimResult: string;
  clientClaimResult: string;
  confirmed: string;
  confirmedAmount: number;
  coverToBeSettledLater: boolean;
  dateOfIssue: Date;
  daysOfDeferment: number;
  deferredPaymentDetails: string;
  descriptionOfGoods: string;
  discountable: boolean;
  discrepancies: string;
  disposalOfDocuments: string;
  docDescription: string;
  docName: string;
  documentPresentationDays: number;
  documentPresentationNarrative: string;
  inventoryValue: number;
  irrevocable: boolean;
  languageCode: string;
  latestDateOfShipment: Date;
  lcMaturity: Date;
  maturityOfCertifiedLoan: number;
  maxAmount: number;
  maxPercCreditAmountTolerance: number;
  minPercCreditAmountTolerance: number;
  mixedPaymentDetails: string;
  numberOfCertfifiedLoanDeal: number;
  otherCondition: string;
  partialShipment: string;
  placeOfExpiry: string;
  placeOfFinalDestination: string;
  placeOfTakingInCharge: string;
  portOfDischarge: string;
  portOfLoading: string;
  postFinanced: boolean;
  prefinanced: boolean;
  referenceToPreAdvice: string;
  revolving: boolean;
  shipmentPeriod: string;
  signatureOk: boolean;
  sortingNumberOfGivenDocuments: number;
  specialPaymentConBen: string;
  specialPaymentRecBank: string;
  standBy: boolean;
  tenorDaysOfBillOfExchange: string;
  toBeConfirmed: string;
  transferable: boolean;
  transshipment: string;
}

export interface TradeFinanceResponse {
  tradeFinance: TradeFinance;
  underWritersInfo?: UnderWritersInfo;
  requestDetailsLayout: ContainerDesc;
  clientDetailsLayout: ContainerDesc;
}

export type ContactReason = { id: string; label: Univ };

export type Id = number | string;

export interface HasId<T> {
  id: T;
}

export interface Comment {
  id: number;
  text: string;
  topic: string;
  contactClientReason: string;
  contactClientReasonUniv: Univ;
  contactBankReason: string;
  contactBankReasonUniv: Univ;
  deadLine: Date;
  createTime: Date;
  lastTime: Date;
  subject: string;
  createdBy: string;
  createUserEmail: string;
  createUserPhone: string;
  requestId: Id;
  unReadByClient?: boolean;
  isCreateInFrontEnd: boolean;
  underWritersInfo: UnderWritersInfo;
  documents: TFDocument[];
}

export interface Notification extends HasId<number> {
  actionNeeded: boolean;
  createDate: Date;
  emailAddress: string;
  finalContent: string;
  relatedEvent: string;
  relatedEventId: string;
  subject: string;
  tradeFinanceId: number;
  tradeFinanceIdentifier: string;
  validUntil: Date;
  referenceNo: string;
  otherPartnerName: string;
  tradeFinanceDescriptorId: string;
  currency: string;
  amount: number;
  documents: TFDocument[];
}

export type ClaimData = {
  claimAmount: number;
  date: Date;
  claimDiscrepancies: string;
  claimResult: string;
  claimAcceptedAmount: number;
  instructionForClient: string;
  informationToClient: string;
  otherInstructionEvent: string;
  currency: string;
  eventState: string;
};

export type DrawingData = {
  tradeFinanceId: number;
  eventId: number;
  state: string;
  stateName: string;
  numberOfDrawing: number;
  claimAmount: number;
  currency: string;
  dateOfRespectiveState: Date;
  paymentValueDate: Date;
  invoiceNumber: string;
  action: string;
  details?: string;
  financialData: FinancialData[];
};

export type PreCheckData = {
  tradeFinanceId: number;
  eventId: number;
  invoiceNumber: string;
  state: string;
  stateName: string;
  action: string;
};

export type UserConfig = {
  id: number;
  dataEntry: string[];
  draftMaker: string[];
  groupA: string[];
  groupB: string[];
  superUser: boolean;
  user: User;
};

export type Partner = {
  id: number;
  bankCity: string;
  bankCountry: string;
  bankCounty: string;
  bankName: string;
  bankStreet: string;
  bankStreetNumber: string;
  bankSwiftCode: string;
  bankZipCode: string;
  name: string;
  partnerCity: string;
  partnerCountry: string;
  partnerCounty: string;
  partnerRegistrationNumber: string;
  partnerStreet: string;
  partnerStreetNumber: string;
  partnerZipCode: string;
  swiftCode: string;
  partnerSwiftAddress: string;
  bankPhoneNr: string;
};

export type UnderWritersInfo = {
  signatureId: number;
  numberOfGroupA: number;
  numberOfGroupB: number;
  membersOfGroupA: string[];
  membersOfGroupB: string[];
  membersOfDraftMakers: string[];
  underWritersInGroupA: string[];
  underWritersInGroupB: string[];
  underWritersInDraftMakers: string[];
  canSign: boolean;
  hasRightToSign: boolean;
  completed: boolean;
  signedByMe: boolean;
  draft: boolean;
  draftMaker: boolean;
  readyForSign: boolean;
};

export type DocumentRequirement = {
  id: Id;
  template: string;
  documentLongName: string;
  originals: string;
  copies: string;
  signed: boolean;
  stamped: boolean;
  tradeFinance: string;
  documentsInvoiceNumber: string;
};

export type Maturity = {
  id: number;
  paymentMethod: string;
  amount: number;
  dueDate: Date | null;
  dueDateText: string | null;
  state: string;
  stateName: Univ;
};

export type CalendarEvents = {
  events: CalendarEvent[];
  holidays: Moment[];
};

export type CalendarEvent = {
  tradeFinanceId: number;
  date: Moment;
  title: string;
  identifier: string;
  referenceNo: string;
  otherPartnerName: string;
  amount: number;
  currency: string;
};

export type Template = {
  id: Id;
  documentName: string;
  documentDescription: string;
};

export interface Clause {
  id: number | undefined;
  clauseDesc: string;
  clauseId: string;
  clauseText: string;
  dealType: string[];
  fieldId: string[];
  transactionType: string[];
  state: "CREATED" | "active" | "archived";
}

export enum ChipsFilter {
  activated = "activated",
  signatureRequired = "signatureRequired",
  ongoing = "ongoing",
  drafts = "drafts",
  expired = "expired",
  all = "all",
}

export enum SortOption {
  amount_asc = "amount_asc",
  amount_desc = "amount_desc",
  dateOfExpiry_asc = "dateOfExpiry_asc",
  dateOfExpiry_desc = "dateOfExpiry_desc",
  otherPartnerName_asc = "otherPartnerName_asc",
  otherPartnerName_desc = "otherPartnerName_desc",
  createDate_asc = "createDate_asc",
  createDate_desc = "createDate_desc",
  businessEntityId_asc = "businessEntityDescriptorId_asc",
  businessEntityId_desc = "businessEntityDescriptorId_desc",
  subtype_asc = "subtype_asc",
  subtype_desc = "subtype_desc",
}

export type TradeFinanceSearchParams = {
  search?: string;
  referenceNo?: string;
  currency?: string[];
  minAmount?: number;
  maxAmount?: number;
  otherPartnerName?: string;
  businessEntityDescriptorId?: string[];
  eventDescriptorId?: string[];
  subType?: string[];
  identifier?: string;
  minDateOfExpiry?: Date;
  maxDateOfExpiry?: Date;
  chipsFilter?: string;
  sortOption?: string;
  events?: string[];
  type?: TradeFinanceType | null;
};

export type PageResult<T> = {
  result: T[];
  offset: number;
  limit: number;
  size: number;
  total: number;
};

export interface TransactionChange {
  tradeFinanceId: number;
  eventId: number;
  name: string;
  number: number;
  state: string;
  stateName: string;
  amount: number;
  issueDate: Date;
  acceptanceDueDate: Date;
  expiryDate: Date;
  currency: string;
  action?: string;
  hasDocuments: boolean;
  amendmentConfirmed: string;
}

export type Fee = {
  id: string;
  description: string;
  amount: number;
  currency: string;
  dueDate: Date;
};

export type FinancialData = {
  state: string;
  amount: number;
  currency: string;
  valueDate: Date;
  invoiceNumbers: string[];
};

export type TransactionDetails = {
  transactionChanges: TransactionChange[];
  fees: Fee[];
  claims: ClaimData[];
  serviceEvents: TFEvent[];
  preCheckEvents: PreCheckData[];
  drawingEvents: DrawingData[];
  financialData: FinancialData[];
};

export type TradeFinancePartner = {
  bankName: string;
  swiftAddress: string;
  swiftCode: string;
};

export type PortalConfig = {
  apiKey: string;
  server: string;
  caas: boolean;
};
